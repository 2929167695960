import { createStore } from 'vuex'

export default createStore({
  state: {
    isFinalScreen: false,
  },
  mutations: {
    SET_IS_FINAL_SCREEN (state, value) {
      state.isFinalScreen = value
    },
  },
  actions: {
    setIsFinalScreen ({ commit }, value) {
      commit('SET_IS_FINAL_SCREEN', value)
    },
  },
  modules: {
  }
})
